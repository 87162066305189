'use client';

import SadShapesImage from '@/assets/images/sad-shapes.png';
import styles from './home/page.module.scss';
import InfoAlert from '@/components/alert/infoAlert';

function Error(props) {
    return (
        <main className={styles.main}>
            <h1>Awww. Something went wrong.</h1>
            <InfoAlert>
                <span>
                    <strong>We're sorry!</strong> If the problem persists -
                    reach out to us on{' '}
                    <a href="/discord" target="_blank">
                        Discord
                    </a>{' '}
                    or live support and we'll fix you up.
                </span>
            </InfoAlert>
            <div className={styles.sadWrapper}>
                <img src={SadShapesImage.src} alt="Picture of sad shapes" />
            </div>
        </main>
    );
}

export default Error;
