import styles from './alert.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/pro-solid-svg-icons';
import { clsx } from 'clsx';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

function InfoAlert({ className, children }) {
    return (
        <div className={clsx([styles.alert, styles.alertInfo, className])}>
            <FontAwesomeIcon icon={faInfoCircle} /> {children}
        </div>
    );
}

export default InfoAlert;
